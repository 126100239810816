<template>
  <div id="app">
    <nav-bar></nav-bar>
    <div id="main" class="container">
      <transition name="none">
        <router-view></router-view>
      </transition>
    </div>
    <nav-footer></nav-footer>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script>
import NavBar from '@/components/global/NavBar.vue';
import NavFooter from '@/components/global/NavFooter.vue';
import {mapGetters} from "vuex";


const App = {
  computed: {
    ...mapGetters({
      account: 'Web3/account',
    }),
  },
  components: {NavBar, NavFooter},

}

export default App;

</script>
