import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import NestingPage from '../views/NestingPage'
import ChainlingsCollection from '../views/chainling/Edition.vue';
// import Earn from '../views/Earn.vue';

import ComingSoon from '../views/ComingSoon.vue';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'root',
        component: ChainlingsCollection,
        props: () => ({edition: "ChainlingsElite"})

    },
    {
        path: '/',
        name: 'home',
        redirect: '/'
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/play',
        name: 'play',
        component: ComingSoon
    },
    {
        path: '/market',
        name: 'market',
        component: ComingSoon
    },
    {
        path: '/earn',
        name: 'earn',
        //component: Earn
        component: ComingSoon
    },

    {
        path: '/edition/',
        component: NestingPage,
        children: [
            {
                path: ':edition',
                component: NestingPage,
                children: [
                    {
                        path: '',
                        props: true,
                        component: () => import(/* webpackChunkName: "collection" */ '../views/chainling/Edition.vue'),
                    },
                    {
                        path: 'id/:id',
                        props: true,
                        component: () => import(/* webpackChunkName: "detail" */ '../views/chainling/Detail.vue'),
                    }
                ]
            },
        ]
    },
    {
        path: '/account',
        name: 'account',
        component: NestingPage,
        children: [
            {
                path: "",
                component: () => import(/* webpackChunkName: "account" */ '../views/account/Account.vue')
            },
            {
                path: ":address",
                component: NestingPage,
                children: [
                    {
                        path: "profile",
                        component: () => import(/* webpackChunkName: "account" */ '../views/account/Profile.vue')
                    },
                    {
                        path: "collection",
                        component: () => import(/* webpackChunkName: "account" */ '../views/account/Collection.vue')
                    },
                    {
                        path: "settings",
                        component: () => import(/* webpackChunkName: "account" */ '../views/account/Settings.vue')
                    }
                ]
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
