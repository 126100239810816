<template>
  <div>
    <web3-modal-vue
        ref="web3modal"
        :theme="theme"
        :provider-options="providerOptions"
        cache-provider
    />

    <div class="chainlings-connect">
      <!-- wen leedo -->
      <div class="d-sm-block d-md-none">
        <!-- connect button -->
        <b-button variant="info" size="sm" v-if="!isActive" @click="connect">
          <span>Connect </span>
        </b-button>

        <!-- connected -->
        <b-button-group v-else>
          <b-button size="sm" variant="light">
            <span>{{ balance }}</span> <i class="cf cf-avax" style="color:#e84142;"></i>
          </b-button>

          <b-dropdown variant="secondary" size="sm" id="dropdown-1" :text="account">
            <!--          <b-dropdown-item to="/account">-->
            <!--            Account-->
            <!--          </b-dropdown-item>-->
            <!--          <b-dropdown-item :to="`/account/${account}/collection`">-->
            <!--            Collection-->
            <!--          </b-dropdown-item>-->
            <!--          <b-dropdown-divider></b-dropdown-divider>-->
            <b-dropdown-item @click="disconnect">
              Disconnect
            </b-dropdown-item>
          </b-dropdown>
        </b-button-group>
      </div>

      <!-- wen big -->
      <div class="d-none d-md-block d-lg-block d-xl-block">
        <!-- connect button -->
        <b-button variant="info" size="md" v-if="!isActive" @click="connect">
          <span>Connect </span>
        </b-button>

        <!-- connected -->
        <b-button-group v-else>
          <b-button size="md" variant="light">
            <span>{{ balance }}</span> <i class="cf cf-avax" style="color:#e84142"></i>
          </b-button>

          <b-dropdown size="md" id="dropdown-1" variant="secondary" :text="account">
            <!--          <b-dropdown-item to="/account">-->
            <!--            Account-->
            <!--          </b-dropdown-item>-->
            <!--          <b-dropdown-item :to="`/account/${account}/collection`">-->
            <!--            Collection-->
            <!--          </b-dropdown-item>-->
            <!--          <b-dropdown-divider></b-dropdown-divider>-->
            <b-dropdown-item @click="disconnect">
              Disconnect
            </b-dropdown-item>
          </b-dropdown>
        </b-button-group>
      </div>
    </div>
  </div>
</template>
<script>
import Web3ModalVue from 'web3modal-vue';
import WalletConnectProvider from '@walletconnect/web3-provider';
import {mapGetters} from 'vuex';
import {AppInstance} from "@/sdk";

const chainId = AppInstance.ConfigService.chainId
const rpcUrl = AppInstance.ConfigService.selectedChainRpcUrl
const rpc = {}
rpc[chainId] = rpcUrl


export default {
  props: {
    size: {
      default: 'md'
    }
  },
  components: {
    Web3ModalVue
  },
  computed: {
    ...mapGetters({
      isActive: 'Web3/isActive',
      account: 'Web3/accountShort',
      accountLong: 'Web3/account',
      balance: 'Web3/balance'
    }),
  },
  data() {
    return {
      theme: 'light',
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc
          }
        },
      }
    };
  },
  created() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.theme = 'dark';
    }
  },
  mounted() {
    this.$nextTick(async () => {
      const web3modal = this.$refs.web3modal;
      this.$store.commit('Web3/setWeb3Modal', web3modal);
      if (web3modal.cachedProvider) {
        this.connect();
      }

    });
  },
  methods: {
    connect() {
      this.$store.dispatch('Web3/connect');
    },
    disconnect() {
      this.$store.dispatch('Web3/resetApp');
    }
  }
}

</script>


<style>
.dropdown-menu {
  top: 3rem;
}
</style>
