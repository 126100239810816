<template>
  <!-- Footer -->
  <footer class="text-center text-lg-start bg-light text-muted bg-transparent border-0">

    <div class="container">
      <!-- Section: Social media -->
      <section
          class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom-0"
      >

        <!-- Left -->
        <div class="me-5 d-none d-lg-block">
          <span>Connect with us:</span>
        </div>
        <!-- Left -->

        <!-- Right -->
        <h4>

          <a href="https://github.com/Orinomics" target="_blank" class="me-4 text-reset px-2">
            <font-awesome-icon :icon="['fab', 'github']"/>
          </a>
          <a href="https://twitter.com/chainlings" target="_blank" class="me-4 text-reset px-2">
            <font-awesome-icon :icon="['fab', 'twitter']"/>
          </a>
        </h4>
        <!-- Right -->
      </section>
    </div>
    <!-- Section: Social media -->

    <!-- Copyright -->
    <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
      © 2022 Copyright:
      <a class="text-reset fw-bold" target="_blank" href="https://chainlings.com/">chainlings.com</a>
    </div>
    <!-- Copyright -->
  </footer>
  <!-- Footer -->
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
footer {
  margin-top: 15em;
  margin-bottom: 0;
}
</style>
