import {parseInt} from "lodash";

export class ConfigService {

    constructor(chainId = 43113, chainConfigs = null) {
        this._chainId = chainId
        this._config = chainConfigs
    }

    get config() {
        return this._config
    }

    get chainId() {
        return this._chainId
    }

    set chainId(chainId) {
        this._chainId = chainId
    }

    get chains() {
        return this.config['chains']
    }

    get selectedChain() {
        return this.chains[this.chainId.toString()]
    }

    get selectedChainExplorer() {
        return this.selectedChain.blockExplorerUrls[0];
    }

    get selectedChainContracts() {
        return this.selectedChain['contracts']
    }

    get selectedChainName() {
        return this.selectedChain['name']
    }

    get selectedChainRpcUrl() {
        return this.selectedChain['rpcUrls'][0]
    }

    get pollingInterval() {
        return this.config['pollingInterval'] || 12000
    }

    /**
     *
     * @returns {string[]}
     */
    get compatibleChains() {
        return Object.keys(this.chains).map(parseInt)
    }
}
