<template>
  <h2 class="stroke chainling-font text-white">
    Coming Soon
  </h2>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
