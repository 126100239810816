<template>
  <div class="charm-card">
    <div class="row align-content-center">
      <div class="col-sm-8 col-md-6 col-md-6 col-lg-4" style="margin: auto">
        <div class="row justify-content-center">
          <b-col>
            Supply: {{ editionInfo.maxSupply - editionInfo.totalSupply }} / {{ editionInfo.maxSupply }}
            <b-progress variant="info" :value="ratio" max="1" class="my-1" height="10px"></b-progress>
          </b-col>
        </div>
        <img class="charm-image" v-bind:class="{ 'in-progress': mintingInProgress }" style="max-width: 100%"
             :src="previewImage"/>
      </div>
    </div>
    <div class="row py-2 align-content-center">
      <div class="col-sm-8 col-md-6 col-md-6 col-lg-4" style="margin: auto">

        <b-input-group style="min-width: 200px">
          <b-input-group-append>
            <b-button
                size="md"
                @click="amount = amount > 0 ? amount - 1: amount"
                variant="danger"
                style="border-top-right-radius: 0; border-bottom-right-radius: 0"
                :disabled="mintingAllowed"
            >
              <b-icon-dash-circle-fill class="charm-button-icon"></b-icon-dash-circle-fill>
            </b-button>
          </b-input-group-append>

          <b-form-input
              size="md"
              v-model="amount"
              style="text-align: center"
              :disabled="mintingAllowed"
          ></b-form-input>

          <b-input-group-prepend>
            <b-button
                size="md"
                @click="amount =editionInfo.maxMintAmount > amount ? amount + 1: amount"
                variant="info"
                style="border-top-left-radius: 0; border-bottom-left-radius: 0"
                :disabled="mintingAllowed"
            >
              <b-icon-plus-circle-fill class="charm-button-icon"></b-icon-plus-circle-fill>
            </b-button>
          </b-input-group-prepend>
        </b-input-group>

        <div class="py-2">
          <b-button size="lg" variant="light" class="charm-button rounded-1" :disabled="mintingAllowed"
                    @click="mint(amount)">

            <strong v-if="ratio > 0">
              {{ mintingInProgress ? '...CHARMING...' : 'CHARM' }}
            </strong>
            <strong v-else>
              Sale Ended
            </strong>

          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {AppInstance} from "@/sdk";
import {mapGetters, mapState} from "vuex";
import confetti from 'canvas-confetti'

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

export default {
  props: {
    edition: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      account: 'Web3/account'
    }),
    ...mapState({
      editionInfo(state) {
        return state.Chainlings[this.edition]
      }
    }),
    ratio() {
      return 1 - (this.editionInfo.totalSupply/this.editionInfo.maxSupply)
    },
    mintingAllowed(){
      return (this.account) && !this.mintingInProgress && (parseInt(this.ratio) === 1)
    }
  },
  data() {
    return {
      editionName: "",
      editionAlias: '',
      ready: false,
      amount: 1,
      previewImage: '',
      mintingInProgress: false,
      mintSucceeded: null
    }
  },
  async beforeMount() {
    this.previewImage = await this.$chainlings.Editions[this.edition].previewImage;
  },
  methods: {
    async mint(amount) {

      this.mintingInProgress = true;

      try {
        const response = await AppInstance.Editions[this.edition].mint(
            this.account,
            amount
        )
        const receipt = await response.wait()

        if (receipt.blockNumber) {
          this.mintSucceeded = true
        }

        for (let x = 0; x < this.amount; x++) {
          confetti({
            angle: randomInRange(55, 125),
            spread: randomInRange(50, 250),
            particleCount: randomInRange(50, 200),
            origin: {y: 0.6}
          });
        }
      } catch (e) {
        await this.transactionFailed(e)
        this.mintSucceeded = false
      }
      this.mintingInProgress = false
    }

  }


}
</script>

<style lang="scss" scoped>


</style>
