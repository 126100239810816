import {Contract, ethers} from 'ethers';
import {makeProvider} from "./contract";
import * as url from "url";

export class Web3Service {

    /**
     *
     * @param configService {ConfigService}
     * @param provider {Provider}
     */
    constructor(configService, provider = null) {
        this.configService = configService
        this._signerAddress = null;
        this.setProvider(provider || makeProvider(this.configService.selectedChainRpcUrl, this.configService.pollingInterval)); // no provider returns a default client
    }

    /**
     * simple rpc provider
     *
     * @returns {Web3Provider}
     */
    get simpleRpcProvider() {
        const provider = new ethers.providers.JsonRpcProvider(this.configService.selectedChainRpcUrl)
        return new ethers.providers.Web3Provider(provider,  this.configService.selectedChain.chainId)
    }

    /**
     * get the provider or a simple rpc provider
     *
     * @returns {*}
     */
    get provider() {
        return this._provider || this.simpleRpcProvider
    }

    /**
     * set the provider
     *
     * @param provider
     */
    setProvider(provider = null) {
        this._provider = provider
    }

    /**
     * sets the account addy that is being used
     *
     * @param address
     */
    setAccount(address){
        this._signerAddress = address
    }

    /**
     * Should be the selected account address
     *
     * @returns {null}
     */
    get account(){
        return this._signerAddress;
    }
    /**
     * create the signer or provier
     *
     * @returns {*}
     */
    get signerOrProvider() {
        return (this._signerAddress) ? this.provider.getSigner(this._signerAddress) : this.provider;

    }

    /**
     * Gives us a fully configured contract
     *
     * @param address {string}
     * @param abi {JSON}
     * @returns {Promise<Contract>}
     */
    async getContract(address, abi) {
        return new Contract(address, abi, await this.signerOrProvider)
    }

    async getTransactionExplorerURL(transactionHash) {
        const explorerUri = new URL(this.configService.selectedChainExplorer);

        const newUri = {
            auth: explorerUri.auth,
            host: explorerUri.host,
            hash: explorerUri.hash,
            href: explorerUri.href,
            pathname: explorerUri.pathname,
            protocol: explorerUri.protocol,
            query: explorerUri.query,
            port: explorerUri.port,
        }
        newUri.pathname = `tx/${transactionHash}`;
        return url.format(newUri);
    }

    /**
     *
     * @param chainId
     * @param chainName
     * @param nativeCurrency
     * @param blockExplorerUrls
     * @param rpcUrls
     * @returns {Promise<void>}
     */
    async requestNetworkChange(
        {
            chainId = "0xa869",
            chainName = "Avalanche Fuji C-Chain",
            nativeCurrency =  {
                name: "Avalanche",
                symbol: "AVAX",
                decimals: 18
            },
            blockExplorerUrls = ["https://testnet.snowtrace.io"],
            rpcUrls= ["https://api.avax-test.network/ext/bc/C/rpc"]
        }
    ) {
        // Check if MetaMask is installed
        // MetaMask injects the global API into window.ethereum
        if (window.ethereum) {
            try {
                // check if the chain to connect to is installed
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{chainId: chainId}], // chainId must be in hexadecimal numbers
                });
            } catch (error) {
                // This error code indicates that the chain has not been added to MetaMask
                // if it is not, then install it into the user MetaMask
                if (error.code === 4902) {
                    try {
                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainId,
                                    chainName,
                                    rpcUrls,
                                    blockExplorerUrls,
                                    nativeCurrency
                                },
                            ],
                        });
                    } catch (addError) {
                        console.error(addError);
                    }
                }
                console.error(error);
            }
            window.ethereum.enable()
        } else {
            // if no window.ethereum then MetaMask is not installed
            alert('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
        }
    }


}
