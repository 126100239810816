<template>
  <div class="loader">
    <div class="loading">
      <div v-for="(letter, key) of text" :class="animation" :key="key">{{letter}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "...Loading..."
    },
    animation: {
      type: String,
      default: 'bounce-6 delay'
    }

  },

}
</script>

<style scoped>

</style>
