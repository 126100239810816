<template>
  <div class="edition">
    <logo></logo>
    <b-card>
      <b-tabs v-model="tabIndex" content content-class="mt-3" pills>
        <b-tab title="Charm" ref="charmTab">
          <div class="row">
            <minter :edition="edition"></minter>
          </div>
        </b-tab>
        <b-tab title="Collection">
          <b-col>
            <b-row v-if="account">
              <loader v-if="!editionInfo.ready"></loader>
              <div class="container" v-else>
                <div class="row justify-content-center"
                     v-if="!accountOwnsAssets">
                  <div>
                    <h3 class="stroke chainling-font text-white">U ain't got none yet, ya goober.</h3>
                  </div>
                </div>
                <div class="row" v-if="accountOwnsAssets">

                  <div class="col-12"  v-if="accountHasOwned">
                    <div class="chainling-font text-white row">Owned</div>

                    <div class="row">
                      <div v-for="(nft, key) of editionInfo.owned" :key="key" class="col-sm-12 col-md-3 col-lg-2"
                           @click="openDetails(key)">
                        <nft-grid-item :nft="nft" :ready="editionInfo.ready"></nft-grid-item>
                      </div>
                    </div>
                  </div>
                  <div class="col-12" v-if="accountHasStaked">
                    <div class="chainling-font text-white row">Staked</div>

                    <div class="row">
                      <div v-for="(nft, key) of editionInfo.staked" :key="key" class="col-sm-12 col-md-3 col-lg-2"
                           @click="openDetails(key)">
                        <nft-grid-item :nft="nft" :ready="editionInfo.ready"></nft-grid-item>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </b-row>
            <div class="row justify-content-center" v-else>
              <div class="">
                <h3 class="stroke chainling-font text-white">Connect ur wallet, dummy</h3></div>
            </div>

          </b-col>
        </b-tab>
      </b-tabs>


    </b-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import NftGridItem from "@/components/collection/nft-grid-item";
import Minter from "./Minter"
import {mapState} from 'vuex'

export default {
  components: {NftGridItem, Minter},
  computed: {
    ...mapGetters({
      account: 'Web3/account',
    }),
    ...mapState({
      editionInfo(state) {
        return state.Chainlings[this.edition]
      }
    }),

    accountOwnsAssets(){
      return this.accountHasOwned || this.accountHasStaked
    },

    accountHasOwned() {
      return Object.keys(this.editionInfo.owned).length
    },

    accountHasStaked() {
      return Object.keys(this.editionInfo.staked).length
    }

  },
  props: ['edition'],
  data() {
    return {
      tabIndex: 0,
    }
  },
  methods: {
    openCharmTab() {
      this.tabIndex = 0
    },
    openDetails(id) {
      this.$router.push(`/edition/${this.edition}/id/${id}`)
    },
  }
}
</script>

<style lang="scss" scoped>


</style>
