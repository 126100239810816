import {AppInstance} from "@/sdk";
import {each} from 'lodash';
import {addressEqual} from '@orinomics/chainlings-sdk/src/utils/contract'

// dynamically create state objects for tokens
const tokensState = {
    ChainlingsElite: {
        alias: AppInstance.Editions.ChainlingsElite.alias,
        previewImage: AppInstance.Editions.ChainlingsElite.previewImage,
        maxSupply: 0,
        totalSupply: 0,
        maxMintAmount: 0,
        tokenName: '',
        ready: false,
        owned: {},
        staked: {}
    }
}

const state = {
    ...tokensState
};

const getters = {};

// dynamic getters
for (const alias in tokensState) {
    getters[alias] = (state) => state[alias]
}


const mutations = {
    setTokenData(state, {alias, value}) {
        state[alias] = {...state[alias], ...value}
    }
};

const actions = {
    async init({state, dispatch}) {
        //for each item in state dispatch a load event
        each(state, (cache) => dispatch('load', cache.alias))
    },
    async load({commit, dispatch, rootGetters, getters}, alias) {
        const address = rootGetters["Web3/account"];
        const contractService = AppInstance.Editions[alias];
        const contract = await contractService.contract

        const stakingContract = await contractService.stakingService.contract;

        stakingContract.on("Stake", async (index, tokenIdBn, contractAddress) => {
            let tokenId = tokenIdBn.toString();
            if (contractAddress === contract.address && Object.keys(getters[alias].owned).includes(tokenId)) {
                await dispatch('moveFromOwnedToStaked', {alias, tokenId})
            }
        });

        stakingContract.on("Unstake", async (index, tokenIdBn, contractAddress) => {
            let tokenId = tokenIdBn.toString();
            if (contractAddress === contract.address && Object.keys(getters[alias].staked).includes(tokenId)) {
                await dispatch('moveFromStakedToOwned', {alias, tokenId})
            }
        });

        contract.on('Transfer', async (from, to, tokenId) => {
            if (addressEqual(to, address)) {
                dispatch('addToOwnedById', {alias, tokenId})
            }

            commit('setTokenData', {
                alias,
                value: {
                    totalSupply: await contractService.totalSupply,
                    maxSupply: await contractService.maxSupply,
                    maxMintAmount: await contractService.maxMintAmount,
                }
            })

        })

        commit('setTokenData', {
            alias,
            value: {
                tokenName: await contractService.tokenName,
                totalSupply: await contractService.totalSupply,
                maxSupply: await contractService.maxSupply,
                maxMintAmount: await contractService.maxMintAmount,
            }
        })

        dispatch('fetchOwned', alias)

    },
    async moveFromOwnedToStaked({state, commit, dispatch}, {alias, tokenId}) {
        const cached = state[alias];
        cached.staked[tokenId] = cached.owned[tokenId];
        delete cached.owned[tokenId]
        commit('setTokenData', {alias, value: cached})

        // update datazzz
        dispatch("fetchOwned", alias)


    },
    async moveFromStakedToOwned({state, commit, dispatch}, {alias, tokenId}) {
        const cached = state[alias];
        cached.owned[tokenId] = cached.staked[tokenId];
        delete cached.staked[tokenId]
        commit('setTokenData', {alias, value: cached})

        // update datazzz
        dispatch("fetchOwned", alias)
    },
    async fetchOwned({state, rootGetters, commit}, alias) {
        const address = rootGetters["Web3/account"];
        if (!address) {
            return
        }
        const cached = state[alias];
        const chainlings = AppInstance.Editions[alias];
        cached.owned = await chainlings.getOwnedByAddressResolved(address)
        // cached.staked = await chainlings.getStakedForAccountResolved()
        cached.ready = true;
        commit('setTokenData', {alias, value: cached})
    },
    async addToOwnedById({state, commit}, {alias, tokenId}) {
        const cached = state[alias]
        cached.owned[tokenId] = await AppInstance.Editions[alias].getTokenByIdResolved(tokenId)
        commit('setTokenData', {alias, value: cached})
    },
    async updateToken({commit}, alias, value) {
        commit('setTokenData', {alias, value})
    }
};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
