<template>
  <b-navbar toggleable="md" class="px-2">
    <!--    branding -->
    <b-navbar-brand to="/" class='mx-0'>
      <div style="max-width: 5rem" class="d-sm-block d-md-none mx-0">
        <b-img :src="brand" class="img-fluid"/>
      </div>
      <div style="" class="d-none d-md-block d-lg-block d-xl-block mx-2">
        <b-img :src="brand" class="img-fluid"/>
      </div>
    </b-navbar-brand>

    <!-- navigation -->
    <b-collapse class="d-none d-md-block d-lg-block d-xl-block" is-nav>
      <b-nav pills>
        <b-nav-item to="/" exact exact-active-class="active">
          Home
        </b-nav-item>
        <b-nav-item to="/market" exact exact-active-class="active">
          Market
        </b-nav-item>
        <b-nav-item to="/play" exact exact-active-class="active">
          Play
        </b-nav-item>
        <b-nav-item to="/earn" exact exact-active-class="active">
          Earn
        </b-nav-item>
      </b-nav>
    </b-collapse>


    <!-- connect button -->
    <connect-button :disabled=false></connect-button>

    <!-- navigation -->
    <div class="container d-sm-block justify-content-center d-md-none">

          <b-nav small pills>
            <b-nav-item to="/market" exact exact-active-class="active">
              Market
            </b-nav-item>
            <b-nav-item to="/play" exact exact-active-class="active">
              Play
            </b-nav-item>
            <b-nav-item to="/earn" exact exact-active-class="active">
              Earn
            </b-nav-item>
          </b-nav>
    </div>


  </b-navbar>

</template>

<script>
import ConnectButton from "@/components/global/wallet/ConnectButton";
import brandIcon from "@/assets/icon.png";

export default {
  data() {
    return {
      brand: brandIcon
    }
  },
  components: {ConnectButton},

}
</script>

<style scoped lang="scss">

</style>
