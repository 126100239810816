import {ethers} from "ethers";

export class StakingService {

    /**
     * @param contractDefinition {JSON}
     * @param configService {ConfigService}
     * @param web3Service {Web3Service}
     */
    constructor(contractDefinition, configService, web3Service) {
        this.configService = configService
        this.web3Service = web3Service
        this.contractDefinition = contractDefinition
    }

    /**
     * the staking contract
     *
     * @returns {Promise}
     */
    get contract() {
        return this.web3Service.getContract(this.configService.selectedChainContracts["Staking"].address, this.contractDefinition.abi)
    }


    /**
     * is token staked?
     *
     * @param contractAddress
     * @param tokenId
     * @returns {Promise<*>}
     */
    async isTokenStaked(contractAddress, tokenId) {
        const contract = await this.contract;
        return await contract.isTokenStaked(contractAddress, tokenId)
    }

    /**
     * stake
     *
     * @param contractAddress
     * @param tokenId
     * @returns {Promise<void>}
     */
    async stake(contractAddress, tokenId) {
        const contract = await this.contract
        return contract.stake(contractAddress, [tokenId])
    }

    /**
     * unstake
     *
     * @param contractAddress
     * @param tokenId
     * @returns {Promise<void>}
     */
    async unstake(contractAddress, tokenId) {
        const contract = await this.contract
        return contract.unstake(contractAddress, [tokenId])
    }

    /**
     * get the pending rewards (fomratted to human numbers)
     *
     * @returns {Promise<string>}
     */
    async getPendingRewards() {
        const contract = await this.contract;
        const amount = (await contract.pendingRewardsForAccount(this.web3Service.account)).toString();
        return ethers.utils.formatUnits(amount);
    }

    async pendingRewardsForToken(contractAddress, tokenId) {
        const contract = await this.contract;
        const amount = (await contract.pendingRewardsForToken(contractAddress, tokenId))
        return ethers.utils.formatUnits(amount);
    }

    /**
     * claim all pending rewards
     *
     * @returns {Promise<void>}
     */
    async claimAll() {
        const contract = await this.contract;
        return await contract.claim(await contract.getAccountStaked(this.web3Service.account))
    }

    /**
     * Gets the staked tokenIds of an account for a contract
     *
     * @param contractAddress
     * @returns {Promise<*>}
     */
    async getStakedTokensForAccount(contractAddress) {
        const contract = await this.contract;
        return await contract.stakedTokensForAccount(contractAddress, this.web3Service.account)
    }


}
