import all from "it-all";
import {concat as uint8ArrayConcat} from 'uint8arrays/concat'
import {toString as uint8ArrayToString} from 'uint8arrays/to-string'
import {fromBuffer} from "file-type/browser";
import {Buffer} from "buffer";

class IpfsRequest {
    constructor({url, headers}) {
        this._url = new URL(url)
        this._headers = new Headers(headers)
    }

    /**
     *
     * @returns {string}
     */
    get url() {
        return this._url.toString();
    }

    /**
     *
     * @returns {Headers}
     */
    get headers() {
        return this._headers
    }
}

class IpfsResponse {
    constructor({data, headers, status, request}) {
        this._data = data;
        this._headers = headers;
        this._status = status;
        this._request = request;
    }


    get data() {
        return this._data;
    }

    get headers() {
        return this._headers;
    }

    get status() {
        return this._status;
    }

    get request() {
        return this._request;
    }
}

export class FileService {

    /**
     *
     * @param ipfs {IPFS}
     * @param axios {axios}
     */
    constructor(ipfs, axios) {
        this.ipfs = ipfs;
        this.axios = axios;
    }

    static getProtocol(resource) {
        const urlParsed = new URL(resource);
        return urlParsed.protocol;
    }

    static isIpfs(resource) {
        const proto = FileService.getProtocol(resource)
        return proto === 'ipfs:';
    }

    async get(resource, options) {

        if (FileService.isIpfs(resource)) {
            return this.getIpfsFileContents(resource, {...options});
        }

        return this.axios.get(resource, options)
    }

    async getIpfsFileContents(resource, {headers, responseType}) {
        const ipfs = await this.ipfs;

        const request = new IpfsRequest({
            url: resource,
            headers,
            responseType
        });

        try {

            // list of promises with teh location of data
            const ipfsResources = ipfs.cat(resource.replace('ipfs://', '/ipfs/'));
            // resolve all promises and combine
            const dataArray = await all(ipfsResources);
            // concat all the data as uint8Array
            const dataConcat = uint8ArrayConcat(dataArray)
            // turn that uint8Array into a string (should be file contents)

            const inferred = (await fromBuffer(dataConcat)) || {mime: null}
            const contentType = inferred.mime || request.headers.get('content-type');


            let data;

            switch (responseType) {
                case 'arraybuffer':
                    data = dataConcat.buffer
                    break;
                case 'blob':
                    data = new Blob(dataConcat);
                    break;
                case 'json':
                    data = JSON.parse(uint8ArrayToString(dataConcat));
                    break;
                case 'text':
                default:
                    data = uint8ArrayToString(dataConcat);
                    break;
            }


            const headers = Object.fromEntries(new Headers({
                'Content-Type': contentType,
                'X-Ipfs-Source': true
            }));

            return new IpfsResponse({
                data,
                headers,
                status: 200,
                request
            });

        } catch (e) {
            console.error(e)
            return new IpfsResponse({
                status: 500,
                request
            })
        }

    }

    async getToBase64(url) {
            const response = await this.get(url, {
                responseType: 'arraybuffer'
            })

            let contents =  new Buffer(response.data).toString('base64')
            let mime = response.headers['content-type']
            return `data:${mime};base64,${contents}`
    }

}
