import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faEnvelope,
  faHome,
  faPhone,
  faPrint,
  faMinusCircle,
  faPlusCircle,
  faEdit
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faDiscord, faGithub, faTelegram, faTwitter} from "@fortawesome/free-brands-svg-icons";
import './styles/custom.scss'
import ChainlingsLogo from "./components/global/Logo"
import Loader from "./components/global/Loading"
import ComingSoon from "./components/global/ComingSoon"
import './registerServiceWorker'
import {AppInstance} from "./sdk";


library.add(faGithub, faTelegram, faDiscord, faTwitter, faEnvelope, faPrint, faHome, faPhone, faMinusCircle, faPlusCircle, faEdit)

Vue.component('logo', ChainlingsLogo)
Vue.component('loader', Loader)
Vue.component('coming-soon', ComingSoon)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Import Bootstrap an BootstrapVue CSS files (order is important)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue, {
  "BSkeleton": {
    "animation": 'throb'
  }
})
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// Vue.use(IpfsPlugin)

// make sdk available as plugin
Vue.use((vue)=> {
  vue.prototype.$chainlings = AppInstance;
})

Vue.config.productionTip = false

store.dispatch('Chainlings/init');
// store.dispatch('Bling/init');

// global methods
Vue.mixin({
  methods: {
    async transactionFailed(e){
      let message = e?.data?.message || e.message;
      let link;
      if (e?.transactionHash) {
        message = "View transaction on Explorer"
        const href = await this.$chainlings.Web3Service.getTransactionExplorerURL(e.transactionHash)
        console.log(href);
        link = {
          href,
        }
      }
      this.$bvToast.toast(message, {
        title: "Transaction Failed",
        variant: 'danger',
        solid: true,
        ...link
      });
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
