<template>
  <div class="d-none d-md-block d-lg-block d-xl-block">
    <img width="300" :src="logo"/>
    <h1 class="stroke chainling-font text-white">
      {{ text }}
    </h1>
  </div>
</template>

<script>
import logo from "@/assets/logo.png"

export default {
  props: {
    text: String
  },
  data() {
    return {
      logo: logo
    }
  }
}
</script>

<style scoped lang="scss">

</style>
