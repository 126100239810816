import {NFTService} from "./src/nftService";
import {ConfigService} from './src/configService'
import {create} from 'ipfs-core'

import {Web3Service} from "./src/utils/web3";
import {CHAIN_ID} from "./constants";
import ChainConfigs from './application.json'
import {StakingService} from "./src/stakingService";

import CeliteDefinition from '@orinomics/chainlings-contracts/build/contracts/ChainlingsElite.json'
import StakingDefinition from "@orinomics/chainlings-contracts/build/contracts/ChainlingsStaking.json";
import BlingDefinition from '@orinomics/chainlings-contracts/build/contracts/ChainlingBling.json'
import {BlingService} from "./src/blingService";
import axios from 'axios';
import {FileService} from "./src/fileService";

/**
 *
 * @param params
 * @constructor
 */
export function ChainlingsSDK({
                 chainId = CHAIN_ID,
                 config = ChainConfigs
             }) {

    this.axios = axios;
    this.IPFS = create()
    this.FileService = new FileService(this.IPFS, this.axios);
    this.ConfigService = new ConfigService(chainId, config)
    this.Web3Service = new Web3Service(this.ConfigService);

    this.Staking = new StakingService(StakingDefinition, this.ConfigService, this.Web3Service);

    this.Bling = new BlingService(BlingDefinition, this.ConfigService, this.Web3Service, this.Staking);
    const ChainlingsElite =  new  NFTService(CeliteDefinition, this.ConfigService, this.Staking, this.FileService, this.Web3Service);

    this.Editions = {
        ChainlingsElite
    }


}

export {ConfigService} from './src/configService'
