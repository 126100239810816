// import chainLinkOracleAbi from '@/config/abi/oracle.json';
import {Contract, ethers} from 'ethers';
import Web3 from 'web3';
import {POLLING_INTERVAL, RPC_URL} from "../../constants";
import ERC721 from '@openzeppelin/contracts/build/contracts/ERC721.json'

/**
 *
 * @param rpcURL
 * @param pollingInterval
 * @returns {*}
 */
export const makeProvider = (rpcURL = RPC_URL, pollingInterval = POLLING_INTERVAL) => {
    let provider = new Web3.providers.HttpProvider(rpcURL || RPC_URL)

    if (rpcURL.match('ws://')) {
        provider = new Web3.providers.WebsocketProvider(rpcURL || RPC_URL)
    }

    const web3NoAccount = new ethers.providers.Web3Provider(provider);
    web3NoAccount.pollingInterval = pollingInterval || POLLING_INTERVAL;
    return web3NoAccount;
};

// export const simpleRpcProvider = new ethers.providers.JsonRpcProvider(RPC_URL);

/**
 * Lists the tokens from the contract -- this is a hack because our current Token does not have listTokens for address
 * @param tokenAddress {string}
 * @param account {string}
 * @param signer {Signer}
 * @returns {Promise<Array<any>>}
 */
export async function listTokensByAddress(tokenAddress, account, signer = null) {
    const signerOrProvider = signer //?? simpleRpcProvider;

    const token = new Contract(
        tokenAddress,
        ERC721.abi,
        signerOrProvider
    );

    const sentLogs = await token.queryFilter(
        token.filters.Transfer(account, null),
    );
    const receivedLogs = await token.queryFilter(
        token.filters.Transfer(null, account),
    );

    const logs = sentLogs.concat(receivedLogs)
        .sort(
            (a, b) =>
                a.blockNumber - b.blockNumber ||
                a.transactionIndex - b.transactionIndex,
        );

    // eslint-disable-next-line no-undef
    const owned = new Set();

    for (const {args: {from, to, tokenId}} of logs) {
        if (addressEqual(to, account)) {
            owned.add(tokenId.toString());
        } else if (addressEqual(from, account)) {
            owned.delete(tokenId.toString());
        }
    }

    return Array.from(owned);
}

/**
 * Convenience function for comparing addresses
 *
 * @param a {string}
 * @param b {string}
 * @returns {boolean}
 */
export function addressEqual(a, b) {
    return a.toLowerCase() === b.toLowerCase();
}

/**
 * Returns the token name from the contract
 * @param tokenAddress {string}
 * @param signer {Signer}
 * @returns {Promise<*>}
 */
export async function getTokenName(tokenAddress, signer = null) {
    const signerOrProvider = signer //?? simpleRpcProvider;

    const token = new Contract(
        tokenAddress,
        ERC721.abi,
        signerOrProvider
    );

    return await token.name();
}

