<template>
  <b-skeleton-wrapper :loading="!ready">
    <template #loading>
      <div no-body img-top class="my-1 my-md-2 my-md-3" style=" max-width: 20rem;">
        <b-skeleton-img v-if="!ready"/>
        <b-card-text class="p-1">
          <b-skeleton width="100%"></b-skeleton>
        </b-card-text>
      </div>
    </template>
    <div class="chainling my-1 my-md-2 my-md-3">
      <div
          :id="nft.dna"
          style="max-width: 20rem;"
      >
        <img class="chainling-img" :src="image" />
        <div class="my-2 font-weight-bolder">#{{nft.edition}} - {{nft.nickname}}</div>

      </div>
      <b-button class="info-button" size="sm">
        <b-icon-info-circle-fill></b-icon-info-circle-fill>
      </b-button>
    </div>
  </b-skeleton-wrapper>
</template>

<script>

export default {
  props: {
    ready: {
      default: false
    },
    nft: {
      default: {
        name: "",
        image: new Promise(()=> {}),
        dna: "",
        edition: null,
        attributes: [
          {trait_type: "", value: ""}
        ]
      }
    }
  },
  async beforeMount() {
    this.image = await this.nft.image
  },
  data() {
    return {
      image: new Promise(()=>{}),
      popped: false
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.chainling {
  position: relative;

  .info-button {
    position: absolute;
    top: 2rem;
    right: 2px;
    visibility: hidden;
    transition: transform 50ms;

  }

  &:hover {
    cursor: pointer;

    .info-button {

      visibility: visible;
      animation: gravity-up-fade 1s forwards;
    }

  }

}

ul.traits {
  list-style: none;
  margin: 0;
  padding: 0;

}


</style>
