import {ethers, utils} from 'ethers';
import {parseInt} from 'lodash';
import {AppInstance} from '@/sdk'

const CHAIN_ID = AppInstance.ConfigService.chainId;

const state = {
    web3Modal: null,
    library: AppInstance.Web3Service.provider,
    active: false,
    account: '',
    chainId: AppInstance.chainId,
    balance: 0,
};

const getters = {
    account: (state) => state.account,
    accountShort: (state) => {
        return state.account.substr(0, 6) + '...' + state.account.substr(state.account.length - 3)
    },
    isActive: (state) => state.active,
    balance: (state) => parseFloat(utils.formatUnits(state.balance)).toFixed(3),
    library: (state) => state.library,
    chainId: (state) => state.chainId,
}

const mutations = {
    setWeb3Modal(state, web3Modal) {
        state.web3Modal = web3Modal;
    },
    setLibrary(state, library) {
        state.library = library;
    },
    setActive(state, active) {
        state.active = active;
    },
    setAccount(state, account) {
        state.account = account;
    },
    setChainId(state, chainId) {
        state.chainId = chainId;
    },
    setBalance(state, balance) {
        state.balance = balance
    }
};


const actions = {
    async connect({state, commit, dispatch}) {
        const provider = await state.web3Modal.connect();
        const library = new ethers.providers.Web3Provider(provider);

        commit('setLibrary', library);

        const accounts = await library.listAccounts();
        if (accounts.length > 0) {
            commit('setAccount', accounts[0]);
        }

        const network = await library.getNetwork();
        commit('setChainId', network.chainId);
        commit('setActive', true);

        dispatch('updateBalances')
        dispatch('updateSdk');

        provider.on('connect', async (info) => {
            const chainId = parseInt(info.chainId);
            commit('setChainId', chainId);
            dispatch('updateSdk');

            console.log('connect', info);
        });

        provider.on('accountsChanged', async (accounts) => {
            if (accounts.length > 0) {
                commit('setAccount', accounts[0]);
                dispatch('updateBalances');
                dispatch('updateSdk');

            } else {
                await dispatch('resetApp');
            }
            console.log('accountsChanged');
        });
        provider.on('chainChanged', async (chainId) => {
            const chainIdNumber = parseInt(chainId);

            commit('setChainId', chainIdNumber);
            dispatch('updateBalances');
            dispatch('updateSdk');


            console.log('chainChanged', chainIdNumber);
        });
    },
    async updateSdk({state, dispatch}) {
        const configService = AppInstance.ConfigService
        configService.chainId = state.chainId
        AppInstance.Web3Service.setProvider(state.library)
        AppInstance.Web3Service.setAccount(state.account)


        if (!configService.compatibleChains.includes(state.chainId)) {

            setTimeout(async ()=>{
                const networkConfig = configService.chains[CHAIN_ID]
                try {
                    await AppInstance.Web3Service.requestNetworkChange({
                        chainId: '0x'+networkConfig.chainId.toString(16),
                        rpcUrls: networkConfig.rpcUrls,
                        name: networkConfig.name
                    })
                } catch (e) {
                    console.error(e)
                }

            }, 3000)

        }

        dispatch("Chainlings/init", null, {root:true})
    },
    async updateBalances({state, commit}) {
        const balance = await state.library.getBalance(state.account);
        commit('setBalance', balance)
    },
    async resetApp({
                       state,
                       commit,
                       dispatch
                   }) {
        try {
            await state.web3Modal.clearCachedProvider();
        } catch (error) {
            console.error(error);
        }
        commit('setAccount', '');
        commit('setActive', false);
        commit('setLibrary', state.library);
        dispatch('updateSdk');

    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
