import {ethers} from "ethers";

export class BlingService {

    /**
     *
     * @param contractDefinition {JSON}
     * @param configService {ConfigService}
     * @param web3Service {Web3Service}
     * @param stakingService {StakingService}
     */
    constructor(contractDefinition, configService, web3Service, stakingService) {
        this.contractDefinition = contractDefinition;
        this.configService = configService
        this.web3Service = web3Service
        this.stakingService = stakingService
    }

    /**
     * Gets the contract for Bling
     * @returns {Promise<Contract>}
     */
    get contract(){
        return this.web3Service.getContract(this.configService.selectedChainContracts['Bling'].address, this.contractDefinition.abi)
    }

    /**
     * Returns the users current bling balance
     *
     * @returns {Promise<string>}
     */
    async balance(){
        const contract = await this.contract;
        return ethers.utils.formatUnits((await contract.balanceOf(this.web3Service.account)).toString());
    }

    /**
     * Returns the pending rewards for bling from the staking contract
     *
     * @returns {Promise<*>}
     */
    async pending(){
        return await this.stakingService.getPendingRewards();
    }
}
