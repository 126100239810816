import {AppInstance} from "../../sdk";

const state = {
    balance: 0,
    pending: 0
};

const mutations = {
    updateBalance(state, balance) {
        state.balance = balance
    },
    updatePending(state, pending) {
        state.pending = pending
    }
};

const getters = {
    balance: (state) => state.balance,
    pendingRewards: (state) => state.pending
};

const actions = {

    async load({commit}) {
        try {
            const balance = await AppInstance.Bling.balance();
            commit('updateBalance', balance);
        } catch (e) {
            console.log('balance not available yet')
            commit('updateBalance', 0);

        }

        try {
            const pending = await AppInstance.Bling.pending();
            commit('updatePending', pending);

        } catch (e) {
            console.log('rewards not available yet')
            commit('updatePending', 0);
        }
    },
    async init({dispatch, rootGetters}) {

        const update = async () => {
            dispatch('load')
        }

        this.watch(() => rootGetters["Web3/isActive"] || (rootGetters["Web3/account"]), update);
        // update anytime we do actions
        (await AppInstance.Staking.contract).on('Withdraw', update);
        (await AppInstance.Staking.contract).on('Stake', update);
        (await AppInstance.Staking.contract).on('Unstake', update);
        (await AppInstance.Editions["ChainlingsElite"].contract).on('Transfer', update);
        (await AppInstance.Bling.contract).on('Transfer', update);

        update();


    }

};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
