import {getTokenName, listTokensByAddress} from "./utils/contract";
import {map} from 'lodash'
import {formatUnits} from "ethers/lib/utils";

export class NFTService {

    /**
     *
     * @param contractDefinition {JSON}
     * @param configService {ConfigService}
     * @param stakingService {StakingService}
     * @param fileService {FileService}
     * @param web3Service {Web3Service}
     */
    constructor(
        contractDefinition,
        configService,
        stakingService,
        fileService,
        web3Service
    ) {
        this.contractDefinition = contractDefinition
        this._alias = contractDefinition.contractName
        this.configService = configService
        this.stakingService = stakingService

        this.fileService = fileService
        this.web3Service = web3Service
    }

    /**
     *
     * @param address
     * @returns {Promise<Array<*>>}
     */
    async listTokensByAddress(address) {
        const contract = await this.contract
        let tmp = [];
        try {
            tmp = await contract.functions.tokensOfOwner(address);
            tmp = map(tmp[0], (value) => value.toNumber())
        } catch (e) {
            console.log("Contract does not have tokensOfOwner method. falling back to event log scan")
            tmp = await listTokensByAddress(this.contractAddress, address, this.web3Service.provider)
        }

        return tmp
    }

    /**
     *
     * @returns {*}
     */
    get contractAddress() {
        return this.configService.selectedChainContracts[this._alias].address
    }

    /**
     *
     * @returns {string}
     */
    get alias() {
        return this._alias;
    }

    /**
     *
     * @returns {string}
     */
    get previewImage() {
        return this.fileService.getToBase64(this.configService.selectedChainContracts[this._alias].preview);

    }

    /**
     * get the max token supply
     *
     * @returns {Promise<*>}
     */
    get maxSupply() {
        return this._getMaxSupply()
    }

    /**
     * get the total token supply
     * @returns {Promise<*>}
     */
    get totalSupply() {
        return this._getTotalSupply()
    }

    /**
     * get the max number of tokens a user can mint
     * @returns {Promise<*>}
     */
    get maxMintAmount() {
        return this._getMaxMintAmount()
    }

    /**
     * maximum amount allowed to be minted
     *
     * @returns {Promise<any>}
     */
    async _getMaxSupply() {
        return (await (await this.contract).functions.maxSupply())[0].toNumber()
    }

    /**
     * current amount minted and in circulation
     * @returns {Promise<any>}
     */
    async _getTotalSupply() {
        return (await (await this.contract).functions.totalSupply())[0].toNumber()
    }

    async _getMaxMintAmount() {
        return (await (await this.contract).functions.maxMintAmount())[0].toNumber()
    }

    /**
     *
     * @returns {Promise<*>}
     */
    get tokenName() {
        return getTokenName(this.contractAddress, this.web3Service.provider)
    }

    /**
     *  the contract instance
     *
     * @returns {Promise<Contract>}
     */
    get contract() {
        return this.web3Service.getContract(this.contractAddress, this.contractDefinition.abi)
    }


    /**
     * Get all nft token data for address with ipfs data resolved
     *
     * @param address
     * @returns {Promise<[]>}
     */
    async getOwnedByAddressResolved(address) {
        if (!address) {
            throw new Error('address cannot be empty')
        }


        const tmp = {};
        const owned = await this.listTokensByAddress(address)
        for (const index of owned) {
            tmp[index] = (await this.getTokenByIdResolved(index))
        }

        console.info(tmp)
        return tmp
    }

    /**
     * Get Token data by tokenId with all ipfs data resolved
     *
     * @param id
     * @returns {Promise<any>}
     */
    async getTokenByIdResolved(id) {
        if (!id) {
            throw new Error('id cannot be empty')
        }

        const nft = await this.contract
        const url = await nft.tokenURI(id)
        const resp = await this.fileService.get(url, {responseType: 'json'})
        const tokenData = resp.data;

        tokenData.nickname = await nft.readNickname(id);
        tokenData.zipper = await nft.getZipper(id);
        tokenData.multiplier = formatUnits(await nft.getStakingMultiplier(id));

        tokenData.image = await this.resolveTokenImage(await tokenData)

        return tokenData;
    }

    /**
     *
     * @param tokenId
     * @returns {Promise<string>}
     */
    async getNickname(tokenId) {
        const nft = await this.contract
        return await nft.readNickname(tokenId)
    }

    /**
     * resolves the image for tokenData (Note: could be static)
     *
     * @param tokenData
     * @returns {Promise<string|*>}
     */
    async resolveTokenImage(tokenData) {
        const url = await tokenData.image;

        return await this.fileService.getToBase64(url, {
            responseType: 'arraybuffer'
        });
    }

    /**
     * Mint a new nft tokenId
     *
     * @param signerAddress
     * @param mintAmount
     * @param params
     * @returns {Promise<*>}
     */
    async mint(signerAddress, mintAmount = 1, params = {}) {
        const config = this.configService.selectedChainContracts[this.alias];
        const contract = await this.contract;
        const totalCostWei = String((await contract.cost()).toString() * mintAmount);
        const totalGasLimit = String(config.GAS_LIMIT * mintAmount);

        return await contract.mint(mintAmount, {
            gasLimit: totalGasLimit,
            value: totalCostWei,
            ...params
        })

    }

    /**
     * Grant spend approval to staking contract
     * @param tokenId
     * @returns {Promise<*>}
     */
    async grantApproval(tokenId) {
        const contract = await this.contract;
        return await contract.approve((await this.stakingService.contract).address, tokenId)
    }

    /**
     * Find out if a token is staked for this contract
     *
     * @param tokenId
     * @returns {Promise<*>}
     */
    async isTokenStaked(tokenId) {
        const address = await this.contractAddress;
        return await this.stakingService.isTokenStaked(address, tokenId);
    }

    /**
     * Get the staked tokens for this account
     *
     * @returns {Promise<*>}
     */
    async stakedForAccount() {
        const contractAddress = await this.contractAddress;
        return (await this.stakingService.getStakedTokensForAccount(contractAddress)).map((i) => i.toString())
    }

    async pendingRewardsForToken(tokenId) {
        const contractAddress = await this.contractAddress;

        return await this.stakingService.pendingRewardsForToken(contractAddress, tokenId);
    }

    async getStakedForAccountResolved() {

        const tmp = {};
        const owned = await this.stakedForAccount()
        for (const index of owned) {
            tmp[index] = (await this.getTokenByIdResolved(index))
            tmp[index].rewards = await this.pendingRewardsForToken(index);
        }

        console.info(tmp)
        return tmp
    }


    /**
     * Stake a tokenId for this contract
     *
     * @param tokenId
     * @returns {Promise<unknown>}
     */
    async stake(tokenId) {
        const contractAddress = await this.contractAddress;
        const response = await this.grantApproval(tokenId);

        // we need to wait for approval first
        await response.wait();

        const response2 = await this.stakingService.stake(contractAddress, tokenId)

        await response2.wait()

        return response2
    }

    /**
     * Unstake a tokenId for this contract
     *
     * @param tokenId
     * @returns {Promise<void>}
     */
    async unstake(tokenId) {
        const contractAddress = await this.contractAddress;
        const response = await this.stakingService.unstake(contractAddress, tokenId);

        await response.wait();

        return response
    }

    async setNickname(tokenId, newName) {
        const contract = await this.contract;
        return await contract.setNickname(tokenId, newName);
    }
}
