<template>
  <div class="play">
    <logo></logo>
    <b-card>
      <coming-soon></coming-soon>
    </b-card>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  components: {
  },
  data(){
    return {
      messages:[
          'GAME TIME!',
          'nobody likes a loser',
          ''
      ],
      playMessage: "Let's play!"
    }
  },
  beforeMount() {

  }
}
</script>
